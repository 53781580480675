import './App.css';
import NavBar from './components/NavBar'
import Home from './components/Home';
import About from './components/About';
import Projects from './components/Projects';
import { Route, Routes } from 'react-router-dom';

function App() {

  return (
    <div className="container">
      <NavBar />
      <Routes>
        <Route index element = {<About />} />
        <Route path="/connect" element = {<Home />} />
        <Route path="/about" element = {<About />} />
        <Route path="/projects" element = {<Projects />} />
      </Routes>
    </div>
  );
}

export default App;
