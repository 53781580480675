import '../styles/pagestyle.css'
import headshot from "../img/Headshot.jpg"

function About() {
    return (
        <div>
            <h3>About</h3>
            <h4>Andrew Fitton</h4>
            <div className="text-area">
                <div className="centered-paragraph">
                    <p>
                        My name is Andrew Fitton, and I'm from Suwanee, Georgia. I am currently studying Computer Science at Georgia Tech, with concentrations in intelligence and information.
                        I love working with those around me to learn more about technology every day, as well as collaborating to deliver effective software that has a positive impact on its users. 
                        Personally, I am passionate about physical fitness and living sustainably. Some fields of software development that I am interested in include:
                    </p>
                </div>
                <div className="center-list">
                    <ul>
                        <li>Machine Learning and AI</li>
                        <li>Data Visualization</li>
                        <li>Data Engineering</li>
                        <li>Web Development</li>
                    </ul>
                </div>
            </div>
            <div className="img-area">
                <img className="pic" src={headshot} alt="Headshot here"/>
            </div>
        </div>
    )
}

export default About;