import '../styles/navstyle.css'
import { Link, useMatch, useResolvedPath } from 'react-router-dom';

function NavBar() {

    const CustomLink =({ to, children, ...props }) => {
        const resolvedPath = useResolvedPath(to)
        const isActive = useMatch({ path: resolvedPath.pathname, end: true })
        
        return (
            <li className={isActive ? "active" : ""}>
                <Link to={to} {...props}>
                    {children}
                </Link>
            </li>
        );
    }
    
    return (
        <div>
            <nav  className='nav'>
                <h1 href = "title" className="site-title"> </h1>
                <ul>
                    <CustomLink to="/about">About</CustomLink>
                    <CustomLink to="/projects">Projects</CustomLink>
                    <li>
                        <a href='/andrewresume.pdf' target='_blank'>Resume</a>
                    </li>
                    <CustomLink to="/connect">Connect</CustomLink>
                </ul>
            </nav>
        </div>
    );
}

export default NavBar;