import '../styles/pagestyle.css';
import bobbydoddPic from '../img/bobbydefense.png';
import p4m from '../img/p4m.png';
import pacman from '../img/pacman.png';
import table from '../img/table.png';

function Projects() {
    return (
        <div>
            <h1>My Projects</h1>
            <div className="text-area">
                <h2>Peace for Moms Mobile Application</h2>
                <div className="centered-paragraph">
                    <p>
                        The Peace for Moms Mobile App is a mobile application for iOS and Android devices to assist front-line doctors in diagnosing and treating mothers facing postpartum depression.
                        Our 5 person development team connected a Google Firebase backend to a React Native frontend UI to support login and profile functionality for thousands of users. We algorithmically 
                        designed 6 screening tools that doctors can fill out using their patient's symptoms, and then provide the doctors with potential diagnoses and treatments for their patient.
                    </p>
                    
                </div>
            </div>
            <div className="img-area">
                <img className="pic" src={p4m} alt="Bobby Dodd Game"/>
            </div>
            <div className="text-area">
                <h2>Pacman AI Project</h2>
                <div className="centered-paragraph">
                    <p>
                        Developed AI algorithms to solve a Pacman game using value iteration and reinforcement learning, training the Pacman object to select the path that statistically maximizes its likelihood to win the round. 
                        We implemented this first using a Markov Decision Process with a value iteration agent. We then transitioned to a Q-Learning agent in which the Pacman learns its most effective choices over a period of trials.
                        Finally, we implemented full joint distribution with a Bayes Network to account for Pacman avoiding unpredictable Ghost characters in playing the game.

                    </p>
                    
                </div>
            </div>
            <div className="img-area">
                <img className="pic2" src={pacman} alt="Pacman Pic"/>
            </div>
            <div className="text-area">
                <h2>Premier League Match Predictor</h2>
                <div className="centered-paragraph">
                    <p>
                        The Premier League match predictor is a personal project in which I pulled statistics from Premier League matches in 2021 and used them to predict outcomes and final standings for the Premier League in the upcoming year.
                        I preprocessed and organized data such as individual match results, home and away form, and player statistics for each team. 
                        I then developed decision trees in a Jupyter Notebooks script to predict each match outcome for 2022, and then compiled results into a final league table.
                        I found that my algorithm predicted an increase in skew between top and bottom teams, and placed over 50% of teams within 3 spots of their actual final standings for 2022.
                        Volatile factors such as player transfers and injuries, coaching changes, and sharp downturn in form for some teams negatively effected the accuracy of my predictions.
                        This project was completed in Python using pandas, sklearn, and matplotlib libraries.
                    </p>
                    
                </div>
            </div>
            <div className="img-area">
                <img className="pic2" src={table} alt="Goal comparison from 2021 to predictions"/>
            </div>
            <div className="text-area">
                <h2>Bobby Dodd Defense Web Game</h2>
                <div className="centered-paragraph">
                    <p>
                        Bobby Dodd Defense is an online game developed using object-oriented programming techniques in Java and JFX. 
                        It was developed in a 3 man development team over 3 months in 2-week long sprints. Our team placed 6th out of 120 teams in a competition graded based on UI experience and code robustness.
                        Our team used Git and Tallo to maintain our codebase and tested all functionality using JUnits throughout development. Here is a link to a video walkthrough of the UI and gameplay experience that my team produced: .
                        <a className="link" href="https://www.youtube.com/watch?v=PszkbBBEKng" target="_blank" rel="noopener noreferrer">Walkthrough</a>
                    </p>
                    
                </div>
            </div>
            <div className="img-area">
                <img className="pic" src={bobbydoddPic} alt="Bobby Dodd Game"/>
            </div>
        </div>
    );
}

export default Projects;