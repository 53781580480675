import linkedinLogo from '../img/linkedin.png';
import githubLogo from '../img/github.png';
import gmailLogo from '../img/gmail.png';
import '../styles/pagestyle.css'

function Home() {
    return (
      <div className='background-container'>
        <h1 href = "title" className="site-title">Connect with me:</h1>
        <div className='centered-paragraph'>
          <ul>
            <li>
                <a href="https://www.linkedin.com/in/andrewfitton/" target="_blank" rel="noreferrer"><img className='logopic' src={linkedinLogo} alt="" /></a>
            </li>
            <li>
                <a href="https://www.github.com/drewfitton" target="_blank" rel="noreferrer"><img className='logopic' src={githubLogo} alt="" /></a>
            </li>
            <li>
                <a href="mailto:drew.fitton1950@gmail.com" target="_blank" rel="noreferrer"><img className='logopic' src={gmailLogo} alt="" /></a>
            </li>
          </ul>
        </div>
      </div>
    
    );
  }
  
  export default Home;